export default [
	{
		path: '/course',
		name: 'course',
		meta: {
			title: '课程管理',
			icon: 'iconfont icon-kechengguanli',
		},
		children: [
			{
				path: '/course/subject',
				name: 'subject',
				meta: {
					title: '科目管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/course/textbook',
				name: 'textbook',
				meta: {
					title: '课程与教材管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/course/statistics',
				name: 'statistics',
				meta: {
					title: '购买统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/traningManagement',
		name: 'traningManagement',
		meta: {
			title: '培训管理',
			icon: 'iconfont icon-peixunguanli',
		},
		children: [
			{
				path: '/traningManagement/traning',
				name: 'traning',
				meta: {
					title: '学习培训',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/teacherHall',
		name: 'teacherHall',
		meta: {
			title: '名师讲堂',
			icon: 'iconfont icon-mingshi',
		},
		children: [
			{
				path: '/teacherHall/classManger',
				name: 'classManger',
				meta: {
					title: '类别管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/teacherHall/teacherManager',
				name: 'teacherManager',
				meta: {
					title: '名师管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/teacherHall/hallManager',
				name: 'hallManager',
				meta: {
					title: '讲堂管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
];
